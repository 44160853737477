*:not(input):not(textarea) {
  -webkit-user-select: none !important; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none !important; /* disable the IOS popup when long-press on a link */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Custom CSS

::-webkit-scrollbar {
  width: 0px;
}

.app-container {
  overflow: hidden;
  height: 100%;
}

.pane {
  position: relative;
}

.pane--orders {
  z-index: 999;
  flex: 0 0 300px;
  box-shadow: 0 0 5px rgba(0,0,0,0.15);
  background-color: #ffffff;
  margin-right: 15px;
  padding: 1rem;
  @media (max-width: 1173px){
    padding: 1rem 0;
  }
}

.card {
  width: calc(50% - 25px);
  min-width: 200px !important;
}

@media (min-width: 1024px) and (max-width: 1173px){
  .pane--orders {
      flex: 0 0 400px;
  }
  .card {
      width: 250px;
  }
}
@media(min-width: 1174px){
  .pane--orders {
    flex: 0 0 450px;
  }

  .card {
      width: 300px;
  }
}
.pane--nav {
  z-index: 999;
  flex: 0 0 70px;
  background-color: #ffffff;
  border-right: solid 2px #f1f1f1;
  margin-left: 15px;
  overflow: hidden;
  height: 100%;
}

.view--orders {
  background-color: #ffffff;
  height: 100%;
}

.view--settings {
  background-color: #ffffff;
  height: 100%;
  overflow-y: scroll;
}

.view--faqs {
  background-color: #ffffff;
  height: 100%;
  overflow-y: scroll;
}

.nav--bottom {
  position: absolute;
  bottom: 0;
  padding: 0;
  list-style: none;
}

.nav--bottom > li {
  padding: 0.5em;
}

.nav--orders {
  height: 60px;
  border-bottom-style: solid;
  border-color: #f1f1f1;
  margin-bottom: 1em;
}

.wrapper--orders {
  height: calc(100vh - 30px);
  overflow-y: scroll;
  margin-left: -15px;
  margin-right: -15px;
}

.wrapper--settings {
  height: calc(100% - 30px);
  margin-left: -15px;
  margin-right: -15px;
  max-width: 720px !important;
}

.wrapper--sidebar {
  height: 100vh;
  overflow-y: scroll;
  margin-left: -15px;
  margin-right: -15px;
}

.wrapper--orders__content{
  height: calc(100% - 90px);
  position: relative;
}

.wrapper--orders__content.shadow {
  box-shadow: inset 0 -15px 15px -15px rgba(0,0,0,0.2)
}

.wrapper--orders__content__arrow {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  opacity: 0.3;
  pointer-events: none;
  height: 25px;
}


.btn--bottomn {
  max-height: 60px;
}


// Order Pane



.order-items, .order-info, .order-adjustments {
  tr td:first-child {
    padding-left: 0;
  }
  tr td:last-child {
    padding-right: 0;
    text-align: right;
  }
  td {
    font-size: 16px;
    margin-bottom: 0;
    letter-spacing: 0;
    color: #484848;
  }
  hr {
    margin: 15px 0;
    width: 100%;
  }
  @media (max-width: 1173px){
    td {
      font-size: 14px;
    }
    hr {
      margin: 10px 0;
    }
  }
}

.order-info {
  td {
    padding-top: 0 !important;
    padding-bottom: 5px !important;
  }
  .order-info__tel, .order-info__date {
    font-weight: 400;
  }
  .order-info__delivery {
    font-weight: 600;
    text-align: left !important;
  }
  .order-info__print a {
    text-decoration: none;
  }
}


.order-item {
    margin-bottom: 5px;
  .order-item__quantity {
    width:1%;
    padding-left: 0;
    vertical-align: top;
    white-space: nowrap;
    span {
      display: inline-block;
      min-width: 27px;
      padding: 0 4px;
      border-radius: 4px;
      color: #ffffff;
      background-color: #E52123;
      text-align: center;
      line-height: 1.5;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .order-item__name {
    font-weight: 600;
    font-size: 16px ;
  }

  .order-item__modifiers {
    list-style: none;
    padding-left: 0;
    li {
      color: #757575;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .order-item__total {
    text-align: right;
    vertical-align: top;
    font-weight: 400;
  }
}

.order-adjustments {
  td {
    padding-top: 0 !important;
    padding-bottom: 5px !important;
  }
  .order-adjustments__subtotal {
    font-weight: 600;
  }
}

.order-total {
  p {
    font-weight: 600;
    color:#484848;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.order-paid-with {
  p {
    font-weight: 400;
    color: #757575;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
